import { action, makeAutoObservable } from "mobx";

export class WheelStore {
  mustSpin = false;
  firstPrizeNum = 0;
  secondPrizeNum = 0;

  constructor() {
    makeAutoObservable(this, {
      toggleMustSpin: action,
      setFirstPrizeNum: action,
    });
  }

  toggleMustSpin(flag) {
    this.mustSpin = flag;
  }

  setFirstPrizeNum(value) {
    this.firstPrizeNum = value;
  }

  setSecondPrizeNum(value) {
      this.secondPrizeNum = value;
  }
}
