import React, { createContext } from "react";
import "./App.css";

//sections
import WheelLayout from "./sections/layout/wheel";
import InputLayout from "./sections/layout/input";

// store
import { DataStore } from "./store/data.store";

// context
export const AppContext = createContext();

function App() {
  return (
    <AppContext.Provider value={new DataStore()}>
      <div className="App">
        <InputLayout />
        <WheelLayout />
      </div>
    </AppContext.Provider>
  );
}

export default App;
