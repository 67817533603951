import React, { useContext } from "react";
import { Wheel } from "react-custom-roulette";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

// style
import "../index.style.css";

// context
import { AppContext } from "../../../App";
import { WheelContext } from "../../layout/wheel";

const DefaultWheel = () => {
  const dataStore = useContext(AppContext);
  const wheelStore = useContext(WheelContext);

  return (
    <>
      {dataStore.wheelDataOneCount > 0 && (
        <div className="content-wrapper">
          <Wheel
            mustStartSpinning={wheelStore.mustSpin}
            prizeNumber={wheelStore.firstPrizeNum}
            data={toJS(dataStore.wheelDataOne)}
            onStopSpinning={() => {
              wheelStore.toggleMustSpin(false);
            }}
            textColors={["white", "black"]}
            outerBorderColor={["white"]}
            outerBorderWidth={3}
            radiusLineWidth={3}
            radiusLineColor={["white"]}
            backgroundColors={["#FF1680", "white"]}
          />
        </div>
      )}
    </>
  );
};

export default observer(DefaultWheel);
