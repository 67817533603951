import React, { createContext, useContext } from "react";
import { Observer } from "mobx-react-lite";

// components
import { FirstWheel, SecondWheel } from "../../wheels";

// context
import { AppContext } from "../../../App";

// store
import { WheelStore } from "../../../store/wheel.store";

// style
import "./index.style.css";

const wheelStore = new WheelStore();

const WheelLayout = () => {
  const dataStore = useContext(AppContext);

  const handleSpinClick = () => {
    wheelStore.toggleMustSpin(true);

    const firstPrizeNumber = Math.floor(Math.random() * dataStore.wheelDataOneCount);
    const secondPrizeNumber = Math.floor(Math.random() * dataStore.wheelDataTwoCount);

    wheelStore.setFirstPrizeNum(firstPrizeNumber);
    wheelStore.setSecondPrizeNum(secondPrizeNumber);
  };

  return (
    <WheelContext.Provider value={wheelStore}>
      <div className="Col-div">
        <div className="Row-div">
          <FirstWheel />
          <SecondWheel />
        </div>
        <Observer>
          {() => {
            return (
              (dataStore.wheelDataOneCount > 0 || dataStore.wheelDataTwoCount > 0) && (
                <button className="spin-button" onClick={handleSpinClick}>
                  {"SPIN"}
                </button>
              )
            );
          }}
        </Observer>
      </div>
    </WheelContext.Provider>
  );
};

export default WheelLayout;
export const WheelContext = createContext();
