import React, { useContext } from "react";
import { Observer } from "mobx-react-lite";

//sections
import { FirstInput, SecondInput } from "../../inputs";

// styles
import "./index.style.css";

// context
import { AppContext } from "../../../App";

// store
import { ConfigStore } from "../../../store/config.store";
const configStore = new ConfigStore();

const InputLayout = () => {
  const dataStore = useContext(AppContext);

  const handleRadioOnChange = (event) => {
    const selectedValue = event?.target?.id;

    if (selectedValue === "wheelTwo") {
      configStore.setNumWheel(2);
    } else {
      configStore.setNumWheel(1);
      dataStore.resetWheelTwo();
    }
  };

  return (
    <div className="layout-input-container">
      <div className="Row-div">
        <input
          type="radio"
          id="wheelOne"
          name="num_wheel"
          onChange={handleRadioOnChange}
        />
        <label htmlFor="wheelOne">{"(1) Wheel"}</label>
        <input
          type="radio"
          id="wheelTwo"
          name="num_wheel"
          onChange={handleRadioOnChange}
        />
        <label htmlFor="wheelTwo">{"(2) Wheels"}</label>
      </div>
      <div className="Row-div">
        <FirstInput />
        <Observer>
          {() => {
            return configStore.numWheels === 2 && <SecondInput />;
          }}
        </Observer>
      </div>
    </div>
  );
};

export default InputLayout;
