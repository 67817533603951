import { action, computed, makeAutoObservable } from "mobx";

export class DataStore {
  wheelDataOne = [];
  wheelDataTwo = [];

  constructor() {
    makeAutoObservable(this, {
      addDataOne: action,
      addDataTwo: action,
      resetWheelTwo: action,
      wheelDataOneCount: computed,
      wheelDataTwoCount: computed,
    });
  }

  addDataOne(dataOne) {
    this.wheelDataOne = dataOne.map((first) => ({ option: first }));
  }

  addDataTwo(dataTwo) {
    this.wheelDataTwo = dataTwo.map((two) => ({ option: two }))
  }

  get wheelDataOneCount() {
    return this.wheelDataOne.length;
  }

  get wheelDataTwoCount() {
    return this.wheelDataTwo.length;
  }

  resetWheelTwo() {
    this.wheelDataTwo = [];
  }
}
