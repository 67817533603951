import React, { useContext } from "react";
import { Wheel } from "react-custom-roulette";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

// style
import "../index.style.css";

// context
import { AppContext } from "../../../App";
import { WheelContext } from "../../layout/wheel";

const OtherWheel = () => {
  const dataStore = useContext(AppContext);
  const wheelStore = useContext(WheelContext);

  return (
    <>
      {dataStore.wheelDataTwoCount > 0 && (
        <div className="content-wrapper">
          <Wheel
            mustStartSpinning={wheelStore.mustSpin}
            prizeNumber={wheelStore.secondPrizeNum}
            data={toJS(dataStore.wheelDataTwo)}
            onStopSpinning={() => {
              wheelStore.toggleMustSpin(false);
            }}
            textColors={["black", "white"]}
            outerBorderColor={["white"]}
            outerBorderWidth={3}
            radiusLineWidth={3}
            radiusLineColor={["white"]}
            backgroundColors={["white", "#0C99A9"]}
          />
        </div>
      )}
    </>
  );
};

export default observer(OtherWheel);
