import { action, makeAutoObservable } from "mobx";

export class ConfigStore {
  numWheels = 1;

  constructor() {
    makeAutoObservable(this, {
      setNumWheel: action,
    });
  }

  setNumWheel(value) {
    this.numWheels = value;
  }
}
