import React, { useRef, useContext } from "react";

// styles
import "../index.style.css";

// context
import { AppContext } from "../../../App";

const OtherInput = () => {
  const dataStore = useContext(AppContext);
  const inputRef = useRef(null);

  const handleAddData = () => {
    const result = inputRef?.current?.value.split(/\r?\n/);
    dataStore.addDataTwo(result);
  }

  return (
    <div className="input-content-wrapper">
      <p className="text-area-title">Second Collection</p>
      <textarea
        className="default-input"
        ref={inputRef}
        placeholder="Add a new line to separate each value"
        autoFocus
      />
      <button className="add-button" onClick={handleAddData}>
        {"ADD"}
      </button>
    </div>
  );
};

export default OtherInput;
